import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import slugify from '@sindresorhus/slugify';

// Components
import Layout from '../../components/layout/Layout';
import RealisationsFilter from '../../components/filter/Realisations';
import PaginationBlock from '../../components/block/Pagination';
import RealisationCard from '../../components/card/Realisation';

// Realisations Language
const RealisationsLanguagePage = ({ pageContext, data }) => {
  
  const {t} = useTranslation();

  const { language_code, languagePath } = pageContext;
  const { totalCount } = data.realisations;

  const pageSchemas = [
    { 
      type: "breadcrumb",
      listItems: [
        { 
          name: t('breadcrumb.realisations'),
          item: languagePath + "realisations/"
        },
        { 
          name: t('breadcrumb.languages'),
          item: languagePath + "realisations/languages/"
        },
        { 
          name: t('realisations.languages.' + language_code + '.label')
        },
      ]
    }
  ];

  return (
    <Layout 
      pageType="classic" 
      pageTitle={t('realisation.languages.' + language_code + '.meta.title', { title: data.site.siteMetadata.title, total_count:totalCount })} 
      pageDescription={t('realisation.languages.' + language_code + '.meta.description', { title: data.site.siteMetadata.title, total_count:totalCount })} 
      pageKeywords={t('realisation.languages.' + language_code + '.meta.keywords', { title: data.site.siteMetadata.title, total_count:totalCount })} 
      pageSchemas={pageSchemas}
      headerClass="navbar-absolute-top navbar-light navbar-show-hide" 
    >
      <div className="container content-space-t-3 content-space-t-lg-3 content-space-b-1 content-space-b-md-2">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to={`/`}>{t('breadcrumb.home')}</Link></li>
            <li className="breadcrumb-item"><Link to={`/realisations/`}>{t('breadcrumb.realisations')}</Link></li>
            <li className="breadcrumb-item"><Link to={`/realisations/languages/`}>{t('breadcrumb.languages')}</Link></li>
            <li className="breadcrumb-item active">{t('realisation.languages.' + language_code + '.label')}</li>
          </ol>
        </nav>
        <div className="w-md-75 w-lg-50 text-center mx-md-auto">
          <h1 className="display-5">{t('realisation.languages.' + language_code + '.title', { title: data.site.siteMetadata.title, total_count: totalCount })}</h1>
          <p className="lead">{t('realisation.languages.' + language_code + '.subtitle', { title: data.site.siteMetadata.title, total_count: totalCount })}</p>
        </div>
      </div>
      <div className="container content-space-b-2 content-space-b-lg-3">
        <div className="row col-lg-divider">
          <div className="col-lg-3">
            <Link className="card-link ms-2" to="/realisations/">
              <div className="card card-info-link card-sm d-grid mb-5 mb-md-3">
                <div className="card-body">
                  <span className="bi-chevron-left small ms-1"></span> {t('back')}
                </div>
              </div>
            </Link>
            <RealisationsFilter data={data} />
          </div>
          <div className="col-lg-9">
            <div className="ps-lg-2">
              <div className="row gx-3 mb-7">
                {data.realisations.nodes &&
                  data.realisations.nodes.map(realisation => (
                    <RealisationCard key={realisation.id} data={realisation} cardStyle="1" cardClassName="col-sm-6 col-lg-6 mb-4"/>
                  ))
                }
              </div>
            </div>
            <div>
              <PaginationBlock 
                path={`realisations/languages/${slugify(language_code)}`} 
                currentPage={pageContext.currentPage} 
                numPages={pageContext.numPages} 
              />
            </div>            
          </div>
        </div>
      </div>
    </Layout>
  )
}

RealisationsLanguagePage.propTypes = {
  data: PropTypes.shape({
    tags: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    categories: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    technologies: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    languages: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
  }),
}

export default RealisationsLanguagePage

export const query = graphql`
  query ($language_code: String, $language: String!, $skip: Int!, $limit: Int!) {
    site: site {
      siteMetadata {
        siteUrl
        title
      }
    }
    realisations: allMdx(
      filter: { 
        fileAbsolutePath: { regex: "/data/realisations/" },
        frontmatter: { languages: { in: [$language_code] }, active: { eq: true }, language: {eq: $language} }
      },
      sort: {fields: frontmatter___date, order: DESC}
      limit: $limit
      skip: $skip
    ) {
      nodes {
        frontmatter {
          meta {
            title
            description
            keywords
          }
          title
          category
          tags
          technologies
          languages
          description
          client
          partners
          services
          image {
            card {
              childImageSharp {
                gatsbyImageData(width: 800)
              }
            }
            alt
          }
          home
          active
          language
          path
          date(formatString: "MMMM YYYY", locale: $language)
        }
        id
        timeToRead
      }
      totalCount
    }
    services: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/realisations/" },
        frontmatter: { active: { eq: true }, language: {eq: $language} } }
      )         
      {
      group(field: frontmatter___services) {
        title: fieldValue
        totalCount
      }
    }
    categories: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/realisations/" },
        frontmatter: { active: { eq: true }, language: {eq: $language} } }
      )         
      {
      group(field: frontmatter___category) {
        title: fieldValue
        totalCount
      }
    }
    tags: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/realisations/" },
        frontmatter: { active: { eq: true }, language: {eq: $language} } }
      )         
      {
      group(field: frontmatter___tags) {
        title: fieldValue
        totalCount
      }
    }
    technologies: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/realisations/" },
        frontmatter: { active: { eq: true }, language: {eq: $language} } }
      )         
      {
      group(field: frontmatter___technologies) {
        title: fieldValue
        totalCount
      }
    }
    languages: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/realisations/" },
        frontmatter: { active: { eq: true }, language: {eq: $language} } }
      )         
      {
      group(field: frontmatter___languages) {
        title: fieldValue
        totalCount
      }
    }
    locales: allLocale(filter: {ns: {in: ["common", "header", "breadcrumb", "realisations", "services", "categories", "tags", "technologies", "languages", "pagination", "footer"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`